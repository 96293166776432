













import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'

import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'

import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ClassicCKEditor extends Vue {
  @Prop(String) value!: string
  @Prop(Boolean) readmode?: boolean
  ckValue = ''

  get editor() {
    return ClassicEditor
  }

  fullEditor = {
    plugins: [
      EssentialsPlugin,
      BoldPlugin,
      ItalicPlugin,
      LinkPlugin,
      ParagraphPlugin,
      AlignmentPlugin,
      ListPlugin,
      HeadingPlugin,
      Indent,
      Image,
      ImageToolbar,
      ImageUpload,
      ImageStyle,
      ImageResize,
      Base64UploadAdapter
    ],
    toolbar: {
      items: [
        'heading',
        'bold',
        'italic',
        'link',
        'alignment',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'outdent',
        'indent',
        'undo',
        'redo'
      ]
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Параграф',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Заголовок 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Заголовок 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Заголовок 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Заголовок 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Заголовок 5',
          class: 'ck-heading_heading5'
        }
      ]
    },
    image: {
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight'
      ],
      styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight']
    }
  }

  get editorConfigCK(): { [key: string]: any } {
    return this.fullEditor
  }

  created() {
    this.ckValue = this.value
  }

  onCKInput() {
    this.$emit('input', this.ckValue)
  }
}

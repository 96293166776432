














import { Vue, Component, Prop } from 'vue-property-decorator'
import AdminAPI from '@/api/requests/admin'
import { FormSchema } from 'vue-form-generator'
import ClassicCKEditor from '@/components/ui/ClassicCKEditor.vue'
import { toastMapper } from '@/store/modules/toast'
import aboutProjectExample from '@/utils/aboutProjectExample'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    ClassicCKEditor
  }
})
export default class AboutProjectEditForm extends Mapper {
  @Prop(Object) content!: Content

  private about = { aboutProject: '' }

  private aboutSchema: FormSchema = {
    fields: [
      {
        type: 'CKEditor',
        label: 'О проекте',
        model: 'aboutProject',
        styleClasses: 'wm-100'
      }
    ]
  }

  private setExample() {
    this.about.aboutProject = aboutProjectExample.aboutProjectExampleText
  }

  private async onSaveClick() {
    const response = await AdminAPI.updateAboutProjectInfo(
      this.about.aboutProject
    )

    if (response.status === 200) {
      this.pushToast({
        time: 5,
        title: 'Информация о проекте',
        message: 'Информация о проекте успешно обновлена'
      })
    }
  }

  private async created() {
    const response = (await AdminAPI.getAboutProjectInfo()).data
    this.about.aboutProject = response.aboutProjectInfo
  }
}
